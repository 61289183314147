<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div class="mt-3 text-center sm:text-left">
                                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">Group Members
                                </DialogTitle>

                                <SkeletonGroupUsers v-if="loading && !groupUsers" />
                                <div class="mt-5 min-h-" v-else>

                                    <div v-if="!groupUsers.length">
                                        <div class="flex justify-center items-center content-center">
                                            <h3 class="p-5">No members.</h3>
                                        </div>
                                    </div>

                                    <ul role="list" class="my-1" @scroll="onScroll" style="height: 500px; overflow: auto" v-else>
                                        <li v-for="row in groupUsers" :key="row"
                                            class="p-5 mt-3 rounded shadow-md hover:shadow-lg shadow-untitled-gray-200 hover:shadow-untitled-gray-200">
                                            <div class="flex items-center space-x-4">
                                                <div class="flex-1 min-w-0">
                                                    <p class="text-sm font-medium text-untitled-gray-900 truncate" :id="`Group-Members-List-Modal-list-${row.user.id}`">
                                                        {{ row.user.name }}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="pb-5 pt-5 text-center" v-if="loading && groupUsers">
                                            <span>Loading . . . </span>
                                        </li>
                                    </ul>

                                </div>
                            </div>

                            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button type="button"
                                    id="Group-Members-List-Modal-Cancel-button"
                                    class="inline-flex justify-center rounded-md border border-untitled-gray-300 bg-white px-4 py-2 text-base font-medium text-untitled-gray-700 shadow-sm hover:text-untitled-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                    @click="cancelButton()" :disabled="loading">Close</button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
      
<script setup>
import { ref, inject, defineProps } from "vue";
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import Bugsnag from '@bugsnag/js';
import EventBus from "@/plugins/eventBus";
import SkeletonGroupUsers from '@/components/loaders/SkeletonGroupUsers.vue';


const props = defineProps({
    eventId: {
        type: String,
        default: ''
    }
})


const axios = inject('axios');
const open = ref(false);
const loading = ref(false);

const group = ref({
    id: null,
    name: ''
});

const groupUsers = ref(null);



const cancelButton = () => {
    open.value = false;
}

const page = ref(1);
const total = ref(0);
const lastPage = ref(1);
const search = ref("");
const sort = ref('asc')

EventBus.$on(`openGroupMemberListModal${props.eventId}`, async (selectedGroup = null) => {
    
    groupUsers.value = null;
    group.value = selectedGroup;
    open.value = true;
    page.value = 1;

    getGroupUsers(selectedGroup);

})

const getGroupUsers = async () => {
    loading.value = true;

    await axios.get(`/api/v3/groups/${group.value.id}/users`, {
        params: {
            page: page.value,
            search: search.value,
            sort: sort.value,
        },
    })
        .then((response) => {

            lastPage.value = response.data.last_page;
            total.value = response.data.total;

            if (page.value == 1) {
                groupUsers.value = response.data.data;
            } else {
                groupUsers.value.push(...response.data.data);
            }

        }).catch(error => {
            Bugsnag.notify(error);
        }).finally(() => {
            loading.value = false;
        });

    open.value = true;
};

const fetchGroups = (value, type) => {
    if (type === "scroll") {
        if (page.value <= lastPage.value) {
            page.value += 1;
            getGroupUsers(value);
        }
    }

    if (type === "sort") {
        page.value = 1;
        getGroupUsers(value);
    }
};

const onScroll = ({ target: { scrollTop, clientHeight, scrollHeight } }) => {
    if (scrollTop + clientHeight >= scrollHeight) {
        fetchGroups(sort.value, "scroll");
    }
};




</script>
      