import { useAuthStore } from "@/stores";
import { computed } from "vue";

export const getRoles = () => {
    const authUser = useAuthStore();
    const user = authUser.$state.user

    const isSuperAdmin = computed(() => {
      return user?.roles.some((role) => role.name === "super-administrator");
    });
      
    const isAccountManager = computed(() => {
      return user?.roles.some((role) => role.name === "account-manager");
    });

    const isSpecificManager = computed(() => {
      return user?.roles.some((role) => role.name === "specific-dealer-manager");
  });

    const isUser = computed(() => {
      const roles = ['learner','salesperson']

      return user?.roles.some((role) => roles.includes(role.name));
    
    });



    return {isSuperAdmin, isAccountManager, isSpecificManager, isUser}
}
