<template>
    <el-select-v2 v-model="timezone" :disabled="disabled" :options="formattedTimezone" :loading="loading"
        :filterable="false" :clearable="true" class="w-full" :placeholder="placeholder">
        <template #default="{ item }">
            <span class="text-untitled-gray-400 text-xs">{{ item.name }}</span>
            <span class="text-untitled-gray-900">
                - {{ item.label }}
            </span>
        </template>
        <template #prefix>
            <span class="pl-2 text-untitled-gray-400" v-if="!timezone">{{ placeholder }}</span>
        </template>
    </el-select-v2>
</template>
<script setup>
import { computed, defineProps, defineEmits, onMounted } from "vue";
import { getTimezones } from '@/composable/timezone/get-timezones'
import { ElSelectV2 } from "element-plus";

const props = defineProps({
    modelValue: null,
    disabled: {
        type: Boolean,
        default: false
    },
    rules: null,
    placeholder: {
        type: String,
        default: 'Select Timezone'
    }
})

const emit = defineEmits(['update:modelValue'])

const disabled = computed(() => props.disabled)
const placeholder = computed(() => props.placeholder)
const timezone = computed({
    get() {
        return replaceUnderscoresWithSpaces(props.modelValue)
    },
    set(newValue) {
        emit('update:modelValue', newValue)
    }
})
const { timezones, loading, load } = getTimezones();


const formattedTimezone = computed(() => {
    return timezones.value.map((timezone) => {
        return {
            value: timezone.timezone,
            label: replaceUnderscoresWithSpaces(timezone.timezone),
            name: timezone.label 
        }
    })
})

const replaceUnderscoresWithSpaces = (str) => {
  if (!str) {
    return '';
  }
  return str.replace(/_/g, ' ');
}

onMounted(() => {
    load()
})

</script>