<script setup>
import { computed, defineProps, defineEmits, ref, onMounted, watch } from 'vue'
import { ElDialog, ElMessageBox } from 'element-plus';

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false
    },
    title: {
        type: String,
        default: 'Title'
    },
    cancelLabel: {
        type: String,
        default: 'Cancel'
    },
    submitLabel: {
        type: String,
        default: 'Submit'
    },
    showClose: {
        type: Boolean,
        default: true
    },
    showActionButton: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    loadingLabel: {
      type: String,
      default: 'Loading'
    },
    dialogDefaultSize: {
      type: String,
      default: '50%'
    }
})
const emit = defineEmits(['update:modelValue', 'onCancel', 'onSubmit'])

const openDialog = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
})
const title = computed(() => props.title)
const submitLabel = computed(() => props.submitLabel)
const cancelLabel = computed(() => props.cancelLabel)
const showClose = computed(() => props.showClose)
const showActionButton = computed(() => props.showActionButton)
const isLoading = computed(() => props.isLoading)
const loadingLabel = computed(() => props.loadingLabel)
const windowSize = ref(window.innerWidth)
const dialogSize = ref('50%')
const widthSize = computed(() => dialogSize.value)

const handleClose = (done) => {
    ElMessageBox.confirm('Are you sure you want to close this?', {
      cancelButtonText: 'No',
      cancelButtonClass: 'bg-yellow',
      confirmButtonText: 'Yes',
      confirmButtonClass: 'dialogCloseOkButtonClass'
    })
    .then(() => {
        emit('onCancel')
        done()
    })
    .catch(() => {
      // catch error
    })
}

const handleClickCancel = () => {
    emit('onCancel')
}

const handleClickCSubmit = () => {
    emit('onSubmit')
}

const updateScreenSize = () => {
    windowSize.value = window.innerWidth;
      if (windowSize.value < 768) {
        dialogSize.value = '100%'
        return
      } else if (windowSize.value < 1024) {
        dialogSize.value = '70%'
        return
      } else {
        dialogSize.value = props.dialogDefaultSize
        return
      }
}

onMounted(() => {
    windowSize.value = window.innerWidth
    updateScreenSize()
    window.addEventListener('resize', updateScreenSize)
    dialogSize.value = props.dialogDefaultSize
})

watch(windowSize, () => updateScreenSize)
</script>
<template>
<el-dialog 
    v-model="openDialog" 
    :before-close="handleClose"
    :show-close="showClose"
    :width="widthSize">
    <template #header="{ titleId, titleClass }">
      <div class="">
        <h3 :id="titleId" :class="titleClass" class="text-base font-semibold leading-2 text-gray-900">{{ title }}</h3>
      </div>
    </template>
    <slot name="content"></slot>

    <template #footer v-if="showActionButton">
      <span class="flex">
        <div class="grow"/>
        <button :disabled="isLoading" class="rounded shadow px-4 mr-2 py-2 flex justify-center items-center text-sm font-semibold text-untitled-gray-900 shadow-sm bg-untitled-gray-100 hover:bg-untitled-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-untitled-gray-600" @click="handleClickCancel">{{ cancelLabel }}</button>
        <button :disabled="isLoading" class="rounded border shadow border-untitled-gray-400 px-4 py-2 flex justify-center items-center text-sm font-semibold text-untitled-gray-900 shadow-sm hover:bg-untitled-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-untitled-gray-600" @click="handleClickCSubmit">
          {{ isLoading ? `${loadingLabel}...` : submitLabel }}
        </button>
      </span>
    </template>
</el-dialog>
</template>
<style>
.dialogCloseOkButtonClass {
  background-color: rgb(107 114 128) !important;
  color: #fff !important;
}

.dialogCloseOkButtonClass:focus { 
  border: rgb(55 65 81) !important;
}

.dialogCloseOkButtonClass:hover {
  border: rgb(55 65 81) !important;
  background-color: rgb(156 163 175) !important;
  color: #fff !important;
}
</style>