<template>
  <div aria-live="assertive"
    class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start mt-14 z-[1000]">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <transition v-for="(notification, index) in notificationsList" :key="index"
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div
          class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div v-if="notification.notificationType === 'success'" class="flex-shrink-0 text-green-500">
                <CheckCircleIcon class="h-6 w-6 " aria-hidden="true" />
              </div>
              <div v-if="notification.notificationType === 'error'" class="flex-shrink-0 text-red-500">
                <ExclamationCircleIcon class="h-6 w-6 " aria-hidden="true" />
              </div>
              <div v-if="notification.notificationType === 'warning'" class="flex-shrink-0 text-yellow-500">
                <ExclamationIcon class="h-6 w-6 " aria-hidden="true" />
              </div>
              <div v-if="notification.notificationType === 'info'" class="flex-shrink-0 text-blue-500">
                <InformationCircleIcon class="h-6 w-6 " aria-hidden="true" />
              </div>
              <div v-if="notification.notificationType === 'server_error'" class="flex-shrink-0 text-red-500">
                <EmojiSadIcon class="h-8 w-8 " aria-hidden="true" />
              </div>

              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p v-if="notification.notificationType !== 'server_error'" class="text-sm font-medium"
                  v-html="notification.content" />
                <div v-else class="flex">
                  <div class="flex-1">
                    <p class="text-sm font-medium" v-html="notification.content"/>
                  </div>
                  <div class="flex justify-center items-center px-1">
                    <button type="button" @click="handleClickReloadPage"
                      class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span class="sr-only">Reload</span>
                      <icon />
                    </button>
                  </div>
                </div>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button type="button" @click="closeNotification(notification.id)"
                  class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
  
<script setup>
import { CheckCircleIcon, XIcon, ExclamationIcon, InformationCircleIcon, ExclamationCircleIcon, EmojiSadIcon } from '@heroicons/vue/solid'
import { computed, onMounted } from "vue";
import eventBus from "@/plugins/eventBus"
import { useNotificationStore } from "@/stores/notification.store";
import { storeToRefs } from "pinia";

const notificationStore = useNotificationStore()
const { notifications } = storeToRefs(notificationStore)

const closeNotification = (id) => notificationStore.closeNotification(id)
const notificationsList = computed(() => notifications.value.filter((notification) => notification))
const icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    class="text-re"
  >
    <path d="M 16 4 C 10.886719 4 6.617188 7.160156 4.875 11.625 L 6.71875 12.375 C 8.175781 8.640625 11.710938 6 16 6 C 19.242188 6 22.132813 7.589844 23.9375 10 L 20 10 L 20 12 L 27 12 L 27 5 L 25 5 L 25 8.09375 C 22.808594 5.582031 19.570313 4 16 4 Z M 25.28125 19.625 C 23.824219 23.359375 20.289063 26 16 26 C 12.722656 26 9.84375 24.386719 8.03125 22 L 12 22 L 12 20 L 5 20 L 5 27 L 7 27 L 7 23.90625 C 9.1875 26.386719 12.394531 28 16 28 C 21.113281 28 25.382813 24.839844 27.125 20.375 Z"></path>
  </svg>
);
const handleClickReloadPage = () => {
  location.reload()
}

onMounted(() => {
  eventBus.$on('CREATE_NOTIFICATION', (message, notificationType = null, timeout = null) => notificationStore.addNotification(message, notificationType, timeout))
})
</script>

