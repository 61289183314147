<template>
    <el-date-picker
        v-bind="$attrs"
        v-model="date"
        :type="type"
        :placeholder="placeholder"
        :format="format"
        :value-format="valueFormat"
        :readonly="readOnly"
        :disabled="disabled"
        :clearable="clearable"
        :disabled-date="disabledDates"
        :size="size"
        :start-placeholder="startPlaceholder"
        :end-placeholder="endPlaceholder"
        :range-separator="rangeSeparator"
        :shortcuts="shortcuts"
      />
</template>
<script setup>
import { ElDatePicker } from 'element-plus';
import { defineProps, defineEmits, computed } from 'vue';
const props = defineProps({
    modelValue: null,
    type: {
        type: String,
        default: 'datetime'
    },
    format: {
        type: String,
        default: 'YYYY/MM/DD hh:mm:ss'
    },
    valueFormat: {
        type: String,
        default: 'YYYY/MM/DD hh:mm:ss'
    },
    readOnly: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    clearable: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: 'Pick a Date'
    },
    disabledBelowDates: {
        type: Boolean,
        default: false
    },
    size: {
        type: String,
        default: 'large'
    },
    startPlaceholder: {
        type: String,
        default: 'Start Date'
    },
    endPlaceholder: {
        type: String,
        default: 'End Date'
    },
    rangeSeparator: {
        type: String,
        default: '-'
    },
    shortcuts: {
        type: Array,
        default: () => []
    }
})

const emit = defineEmits(['update:modelValue'])

const date = computed({
    get() {
        return props.modelValue
    },
    set(newValue) {
        emit('update:modelValue', newValue)
    }
})
const type = computed(() => props.type);
const format = computed(() => props.format);
const valueFormat = computed(() => props.valueFormat);
const readOnly = computed(() => props.readOnly);
const disabled = computed(() => props.disabled);
const clearable = computed(() => props.clearable);
const placeholder = computed(() => props.placeholder)
const disabledBelowDates = computed(() => props.disabledBelowDates)
const size = computed(() => props.size)
const startPlaceholder = computed(() => props.startPlaceholder)
const endPlaceholder = computed(() => props.endPlaceholder)
const rangeSeparator = computed(() => props.rangeSeparator)
const shortcuts = computed(() => props.shortcuts)

const disabledDates = (time) => {
    if(disabledBelowDates.value) {
        return time.getTime() < Date.now() - 86400000;
    }
}
</script>