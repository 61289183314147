<template>
  <div class="py-10 w-full px-6">
    <div class="flex flex-col lg:flex-row w-full lg:items-center xl:w-2/3">
      <div class="w-full lg:w-1/2  mb-4 lg:mb-0 lg:mr-3 md:flex-1">
        <input v-model="search" type="text" placeholder="Search" @keyup.enter="searchCourse"
          class="cursor-pointer focus:outline-none focus:border-gray-800 focus:shadow-outline-gray text-sm form-select block w-full py-2 px-2 xl:px-3 border border-gray-300 rounded text-gray-600 appearance-none bg-transparent" />
      </div>
      <div class="">
        <CourseFilters v-model="selectedFilter" @applyFilters="handleApplyFilters" />
      </div>
      <div class="mx-4">

        <button type="button" @click="handleClickResetFilter"
          class="relative flex gap-x-2 bg-white justify-center items-center shadow hover:bg-untitled-gray-200 cursor-pointer focus:outline-none focus:border-untitled-gray-200 focus:shadow-outline-gray text-sm form-select w-1/8 py-2 px-2 xl:px-3 border border-untitled-gray-100 rounded appearance-none text-untitled-gray-800 hover:text-untitled-gray-900">Reset</button>
      </div>
    </div>
  </div>
  <div class="text-center" v-if="isLoading">
    <SkeletonCourseCard v-for="i in 4" :key="i" class="mb-2" />
  </div>
  <div v-else class="overflow-y-scroll md:h-[800px] lg:h-[2000px] xl:h-auto">
    <template v-if="courses.length > 0">
      <div v-for="(course, index) in courses" :key="index">
        <SingleCourse class="mt-3 mb-3" :course="course" />
      </div>
    </template>
    <div class="text-center" v-else>
      <div class="block">No courses found.</div>
    </div>
  </div>

  <!-- Assign Course -->
  <NotificationDialog v-if="showNotificationDialog" :content="message" @close="closeNotificationDialog"
    :close-dialog="showNotificationDialog" />
  <TransitionRoot appear :show="open" as="template">
    <Dialog as="div" class="relative z-[1000]">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 h-3/6">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="relative w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button @click="open = false" type="button"
                  class="rounded-md bg-white text-untitled-gray-400 hover:text-untitled-gray-500">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-untitled-gray-900">
                Assign {{ selectedCourse?.name }} Course
              </DialogTitle>

              <div class="mt-2">
                <AssignUser v-if="open" @assign="getAssignedUser" @createGroup="handleClickCreateGroup" :close="() => {
                  open = false;
                }
                  " :users="users" :groups="groups" :dealers="dealers" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <GroupFormModal :eventId="groupFormModalEventId" />
</template>
<script setup>
import SingleCourse from "@/components/home/courses/SingleCourse";
import SkeletonCourseCard from "@/components/loaders/SkeletonCourseCard";
import { useRouter } from 'vue-router';
import Bugsnag from "@bugsnag/js";
import EventBus from '@/plugins/eventBus';
import { ref, watch, inject, onMounted, defineAsyncComponent, provide } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import AssignUser from "@/components/course/unit/partials/forms/AssignUser.vue";
import NotificationDialog from "@/components/partials/NotificationDialog.vue";
import GroupFormModal from '@/components/groups/GroupFormModal';
import {
  XIcon
} from "@heroicons/vue/outline";

import { getUsers, getGroups, getDealers } from "@/composable/users/getUsers";
import { useLmsSettingsStore } from "@/stores/useLmsSettingsStore";
const CourseFilters = defineAsyncComponent(() => import('./filters/CourseFilters.vue'))

const { dealerList, dealers } = getDealers();
const { userList, users, } = getUsers();
const { groupList, groups } = getGroups();


const router = useRouter();
const lmsSettingStore = useLmsSettingsStore();
const axios = inject('axios')

const selectedCourse = ref(null);
const groupFormModalEventId = `assignCourse${selectedCourse.value?.id}`;
const assign = ref(null);
const showNotificationDialog = ref(false);
const message = ref(null);
const open = ref(false);

const courses = ref([])
const allCourses = ref([])
const sortBy = ref(null);
const filteredBy = ref(null);
const isLoading = ref(false)
const search = ref('');
const selectedFilter = ref({
  sort: null,
  filter: null,
  available_course: false,
  enrolled_course: true,
  course_category: []
})

const fetchCourses = async () => {
  isLoading.value = true;
  await axios
    .post("api/v3/courses/list", {
      keyword: search.value,
        ...selectedFilter.value
    })
    .then((response) => {
      courses.value = response.data;
      if(lmsSettingStore.hasEnrollment && courses.value.length === 0 && selectedFilter.value.enrolled_course && !selectedFilter.value.available_course) {
        selectedFilter.value.available_course = true;
        selectedFilter.value.enrolled_course = false;
        fetchCourses();
        return
      }
      allCourses.value = response.data;
      isLoading.value = false;

      if(selectedFilter.value && selectedFilter.value.filter) {
        changeFilter(selectedFilter.value.filter);
      }
      if(selectedFilter.value && selectedFilter.value.sort) {
        changeSort(selectedFilter.value.sort);
      }
    })
    .catch((error) => {
      Bugsnag.notify(error);
    });
}

const handleClickCreateGroup = () => {
  EventBus.$emit(`openGroupFormModal${groupFormModalEventId}`);
}

const getAssignedUser = (value) => {
  if (value) {
    assign.value = value;
    handleSubmit();
  }
};



const handleSubmit = () => {

  if (assign?.value.assigned_users?.length == 0 && assign?.value.assign_groups?.length == 0
  ) {
    message.value = "Please select a user to assign this course";
    showNotificationDialog.value = true;
    return;
  }

  axios
    .post("/api/v3/courses/assign", {
      course_id: selectedCourse.value.id,
      users_id: assign?.value.assigned_users,
      groups_id: assign?.value.assigned_groups,
      dealers_id: assign?.value.assigned_dealers,
      due_date: assign?.value.due_date,
    })
    .then(() => {
      EventBus.$emit('REFRESH_NOTIFICATION')
      EventBus.$emit(
        'CREATE_NOTIFICATION',
        'Assigned successfully',
        'success',
        6500
      )
      open.value = false;
    })
    .catch((error) => {
      Bugsnag.notify(error);
    });
};

const closeNotificationDialog = () => {
  showNotificationDialog.value = false;
};


const changeFilter = (selectedFilterBy) => {
  switch (selectedFilterBy) {
    case "not started":
      courses.value = allCourses.value.filter((course) => {
        return course.completed_percentage == 0;
      });
      break;
    case "in progress":
      courses.value = allCourses.value.filter((course) => {
        return course.completed_percentage > 0 && course.completed_percentage < 100;
      });
      break;
    case "completed":
      courses.value = allCourses.value.filter((course) => {
        return course.completed_percentage == 100;
      });
      break;
    case "all":
      courses.value = allCourses.value;
      break;
  }
}

const handleClickResetFilter = () => {
  selectedFilter.value = {
    sort: null,
    filter: null,
    available_course: false,
    enrolled_course: false,
    course_category: []
  }

  fetchCourses();
}

const changeSort = (selectedSort) => {
  switch (selectedSort) {
    case "a-z":
      courses.value = courses.value.sort((a, b) =>
        a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : b.name?.toLowerCase() > a.name?.toLowerCase() ? -1 : 0
      );
      break;
    case "z-a":
      courses.value = courses.value.sort((a, b) =>
        a.name?.toLowerCase() < b.name?.toLowerCase() ? 1 : b.name?.toLowerCase() < a.name?.toLowerCase() ? -1 : 0
      );
      break;
    case "lth":
      courses.value = courses.value.sort((a, b) =>
        a.completed_percentage > b.completed_percentage
          ? 1
          : b.completed_percentage > a.completed_percentage
            ? -1
            : 0
      );
      break;
    case "htl":
      courses.value = courses.value.sort((a, b) =>
        a.completed_percentage < b.completed_percentage
          ? 1
          : b.completed_percentage < a.completed_percentage
            ? -1
            : 0
      );
      break;
    case "default":
      courses.value = courses.value.sort((a, b) =>
        a.id > b.id ? 1 : b.id > a.id ? -1 : 0
      );
      break;
  }
}

const searchCourse = () => {
  isLoading.value = true;
  router.push({
    name: 'search',
    query: {
      search: search.value
    }
  })
}

const handleApplyFilters = () => {
  fetchCourses();
}

onMounted(async () => {
  fetchCourses();



  EventBus.$on('reset-course-list', () => {
    fetchCourses();
  });

  EventBus.$on('addGroup', async (group) => {
    groups.value.push(group)
  })

  await dealerList();
  await userList();
  await groupList();

})

watch(filteredBy, () => {
  changeFilter();
})

watch(sortBy, () => {
  changeSort()
})

EventBus.$on('toggleAssignCourse', (course) => {
  open.value = true;
  selectedCourse.value = course;
});

provide('fetchCourses', {
  fetchCourses,
})

</script>
