import { defineStore } from "pinia";
import { useAuthStore } from "./auth.store";

export const useLmsSettingsStore = defineStore('LmsSettingsStore', {
    getters: {
        authUser() {
            return useAuthStore().user;
        },
        hasEnrollment() {
            if(this.authUser && this.authUser.dealer && this.authUser.dealer.lms_settings && this.authUser.dealer.lms_settings.length) {
                return this.authUser.dealer.lms_settings.some((setting) => {
                    return setting.system_name === 'course_enrollment';
                })
            }

            return false;
        },
        hasRequireArp() {
            if(this.authUser && this.authUser.dealer && this.authUser.dealer.lms_settings && this.authUser.dealer.lms_settings.length) {
                return this.authUser.dealer.lms_settings.some((setting) => {
                    return setting.system_name === 'require_arp';
                })
            }

            return false;
        }
    }
})