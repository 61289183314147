<template>
    <div v-bind="$attrs">
        <div class="flex rounded-md shadow-sm">
            <span v-if="!isIcon && showIcon"
                class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">{{
                    title }}</span>
            <div v-if="isIcon && showIcon"
                class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                <component :is="icon" class="w-4" />
            </div>

            <el-select 
                v-model="selected" 
                :placeholder="placeholder" 
                :size="size" @change="handleChange"
                :clearable="clearable"
                class="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6">
                <el-option v-for="item in options" :key="item[value]" :label="item[label]" :value="item[value]" />
            </el-select>
        </div>
    </div>
</template>
<script setup>
import { computed, defineProps, defineEmits } from "vue";
import { ElSelect, ElOption } from "element-plus";

const props = defineProps({
    size: {
        type: String,
        default: "large",
    },
    options: {
        type: Array,
        default: () => [],
    },
    label: {
        type: String,
        default: "name",
    },
    value: {
        type: String,
        default: "value",
    },
    placeholder: {
        type: String,
        default: "Select value",
    },
    modelValue: null,
    isIcon: {
        type: Boolean,
        default: false,
    },
    icon: null,
    title: {
        type: String,
        default: "Title",
    },
    clearable: {
        type: Boolean,
        default: false,
    },
    showIcon: {
        type: Boolean,
        default: true,
    }
});

const emit = defineEmits(["update:modelValue", 'change']);
const size = computed(() => props.size);
const options = computed(() => props.options);
const label = computed(() => props.label);
const value = computed(() => props.value);
const placeholder = computed(() => props.placeholder);
const icon = computed(() => props.icon);
const isIcon = computed(() => props.isIcon);
const title = computed(() => props.title);
const clearable = computed(() => props.clearable);
const showIcon = computed(() => props.showIcon);
const selected = computed({
    get() {
        return props.modelValue;
    },
    set(newValue) {
        emit("update:modelValue", newValue);
    },
});

const handleChange = (event) => {
    emit('change', event);
}
</script>
