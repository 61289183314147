export default function (user = null) {
  // Append the script at the end of the body tag
  const script = document.createElement("script");
  script.async = true;
  script.src =
    "https://www.socialintents.com/api/chat/socialintents.1.3.js#2c9fab3589616a75018963f527e6017c";
  document.body.appendChild(script);

  script.onload = () => {
    if (!user) {
      return;
    }

    const socialIntentsScript = document.createElement("script")
    socialIntentsScript.innerHTML = `
      function onSIApiReady() {
        //Will display the tab once the scripts have loaded
        SI_API.setChatInfo('${user.name}', '${user.email}','(123) 123-1234','Marketing');
      };
    `

    document.body.appendChild(socialIntentsScript);
  }
}