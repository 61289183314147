import { ref } from 'vue';
import { defineStore } from 'pinia'
import { uniqueId as _uniqueId } from 'lodash-es'

export const useNotificationStore = defineStore('notification', () => {
  const notifications = ref([])

  const addNotification = (message, notificationType = null, timeout = null) => {
    const notification = {
      content: message,
      id: _uniqueId('notification_'),
    }

    if (timeout) {
      notification.timeout = timeout
    }

    if (notificationType) {
      notification.notificationType = notificationType
    }

    notification.timeoutId = setTimeout(() => closeNotification(notification.id), notification.timeout || 5000)

    notifications.value.push(notification)
  }

  const closeNotification = (id) => {
    const index = notifications.value.findIndex((notification) => notification.id === id)
    notifications.value.splice(index, 1)
  }

  return {
    notifications,
    addNotification,
    closeNotification,
  }
})