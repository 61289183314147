<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0">
        <div class="flex min-h-full items-center justify-center p-4 h-3/6">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                Change Video
              </DialogTitle>
              <div class="mt-5 flex flex-col gap-3 h-full">
                <form>
                  <div class="flex">
                    <Switch
                      v-model="featuredVideo.fileUploadOrLink"
                      :class="
                        featuredVideo.fileUploadOrLink
                          ? 'bg-untitled-gray-800'
                          : 'bg-untitled-gray-400'
                      "
                      class="relative inline-flex h-[24px] w-[60px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      <span
                        aria-hidden="true"
                        :class="
                          featuredVideo.fileUploadOrLink
                            ? 'translate-x-9'
                            : 'translate-x-0'
                        "
                        class="pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
                      />
                    </Switch>
                    <small class="ml-2">Switch to video upload</small>
                  </div>
                  <div class="mt-3">
                    <template v-if="!featuredVideo.fileUploadOrLink">
                      <input
                        v-model="featuredVideo.videoUrl"
                        class="block w-full appearance-none rounded-md border border-untitled-gray-300 px-3 py-2 placeholder-untitled-gray-400 shadow-sm focus:border-untitled-gray-500 focus:outline-none focus:ring-untitled-gray-500 sm:text-sm"
                        type="text"
                        placeholder="Enter video URL"
                      />
                      <span
                        class="text-red-500 text-xs font-medium"
                        v-if="errorMessage?.length"
                      >
                        <small>{{ errorMessage }}</small>
                      </span>
                    </template>
                    <template v-else>
                      <file-pond
                        className="cursor-pointer"
                        label-idle="Drop your video file here or click to browse your computer (Accepted File : mp4, mov, mkv)"
                        ref="pond"
                        credits="false"
                        allow-multiple="false"
                        allowFileTypeValidation="true"
                        allowFileSizeValidation="true"
                        fileValidateTypeDetectType="true"
                        max-files="1"
                        maxFileSize="200MB"
                        allowRemove="true"
                        allowProcess="false"
                        checkValidity="false"
                        instant-upload="false"
                        v-bind:files="myFiles"
                        :beforeRemoveFile="handleFilePondRemoveFiles"
                        v-on:updatefiles="handleFilePondUpdateFiles"
                        v-on:init="handleFilePondInit"
                        accepted-file-types="video/mp4, video/x-matroska, video/quicktime"
                        :disabled="isLoading"
                        :server="{
                          process: (
                            fieldName,
                            file,
                            metadata,
                            load,
                            error,
                            progress,
                            abort
                          ) => {
                            uploadFile(
                              file,
                              metadata,
                              load,
                              error,
                              progress,
                              abort
                            );
                          },
                        }"
                      />
                    </template>
                  </div>

                  <div v-if="isSuperAdmin" class="mt-3">
                    <e-combobox
                      v-model="selectedCompanies"
                      name="companies"
                      :options="companies"
                      :loading="loadingCompanies"
                      option-name="name"
                      value-key="id"
                      :multiple="true"
                      :filterable="true"
                      :clearable="true"
                      placeholder="Start typing to search companies..."
                    />
                  </div>
                  <div class="mt-14 flex justify-end">
                    <v-button
                      type="button"
                      class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
                      @click.prevent="onSubmit"
                      :loading="isLoading"
                      :disabled="isDisabled || isLoading"
                    >
                      Save
                    </v-button>
                    <v-button
                      type="button"
                      class="ml-2 inline-flex w-full justify-center rounded-md border border-untitled-gray-300 bg-untitled-gray-500 px-4 py-2 text-base font-medium text-untitled-gray-100 shadow-sm hover:text-untitled-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      :class="`${
                          isLoading
                          ? 'bg-[#f6f6f6] text-untitled-gray-500 font-[400]'
                          : 'bg-untitled-gray-500 text-white  hover:bg-untitled-gray-300'
                      } `"
                      @click="closeModal"
                      :loading="isLoading"
                      :disabled="isLoading"
                    >
                      Cancel
                    </v-button>
                  </div>
                </form>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script setup>
import { ref, defineProps, onMounted, computed, watch, inject } from "vue";
import { debounce as _debounce } from "lodash-es";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
  Switch,
} from "@headlessui/vue";

import { useAuthStore } from "@/stores";
import eventBus from "@/plugins/eventBus";
import vueFilePond from "vue-filepond";
import { VButton } from "revdojo-vue-components";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPlugFileValidateSize from "filepond-plugin-file-validate-size";

import ECombobox from "@/components/element-components/ECombobox";
import { isVimeoAvailable,isYoutubeAvailable } from '@/helpers/index'
import { store } from "@/signedUrl";
import Bugsnag from "@bugsnag/js";

const axios = inject("axios");
const authUser = useAuthStore();

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPlugFileValidateSize
);

const props = defineProps({
  isOpen: {
    default: false,
    type: Boolean,
  },
  handleCloseModal: {
    type: Function,
  },
});

const featuredVideo = ref({
  name: "",
  videoUrl: "",
  startDate: null,
  endDate: null,
  videoType: 1,
  expiration: true,
  type: 2,
  fileUploadOrLink: false,
  videoFile: null,
  relatedUnits: null,
  youtube_id: null,
  fileVideoURL: null,
});


const isDisabled = ref(false)
const selectedCompanies = ref([]);
const isFile = ref();
const companies = ref([]);
const loadingCompanies = ref(false);
const isLoading = ref(false);
const pond = ref();
const errorMessage = ref("");
watch(
  () => featuredVideo?.value?.videoUrl,
  async (value) => {
    if(value?.length){
      errorMessage.value = "";
      fetchVideoId(value);
    }
  
  }
);

const isValid = () => {
  return featuredVideo.value.videoUrl || isFile.value;
};
const onSubmit = () => {
  if (!isValid()) return;

  isLoading.value = true;
  if (isFile.value) {
    pond.value.processFiles();
  } else {
    saveVideo();
  }
};
const handleFilePondRemoveFiles = () => {
  isFile.value = false;
};
const handleFilePondUpdateFiles = () => {
  isFile.value = pond.value.getFile() !== null;
};
const handleFilePondInit = () => {
  isFile.value = pond.value.getFile() !== null;
};
const uploadFile = async (file, metadata, load, error, progress, abort) => {
  try {
    const result = await store(file);

    if (!result?.key) {
      isLoading.value = false;
      error();

      return;
    }

    featuredVideo.value.fileVideoURL = result?.key;
    await saveVideo();
    load(file);
  } catch {
    isLoading.value = false;
    error();
  }

  return {
    abort: () => {
      abort();
    },
  };
};
const closeModal = () => {
  props?.handleCloseModal();
};

const saveVideo = () => {
  isLoading.value = true;
  featuredVideo.value.name = user?.value?.dealer.name;


  const payload = {
    fileVideoURL: featuredVideo?.value?.fileVideoURL,
    name: featuredVideo?.value?.name,
    videoUrl: featuredVideo.value.youtube_id,
    startDate: null,
    endDate: null,
    videoType: featuredVideo.value.videoType,
    expiration: false,
    type: featuredVideo.value.type,
    fileUploadOrLink: featuredVideo.value.fileUploadOrLink,
    dealers: selectedCompanies.value,
    relatedUnits: ''
  }


  axios
    .post("/api/featured-videos", payload)
    .then(({ data }) => {
      const message =  featuredVideo?.value?.fileVideoURL ? 'You have successfully changed the featured video. Please wait for the video to still process on our end.' : 'You have successfully changed the featured video.'
      
      featuredVideo.value = {
        name: "",
        videoUrl: "",
        startDate: null,
        endDate: null,
        videoType: 1,
        expiration: true,
        type: 2,
        fileUploadOrLink: false,
        videoFile: null,
        relatedUnits: null,
      };

      isLoading.value = false;
      eventBus.$emit("CHANGE_FEATURED_VIDEO", data);

    
      eventBus.$emit("NOTIFICATION_DIALOG", {
        show: true,
        message: message
      });
      closeModal();
    })
    .catch((error) => {
      isLoading.value = false;
      Bugsnag.notify(error);
    });
};
const getCompaniesAndDealers = () => {
  loadingCompanies.value = true;

  axios
    .get("/api/companies-and-dealers")
    .then(({ data }) => {
      companies.value = data.data.companies;
      loadingCompanies.value = false;
    })
    .catch((error) => {
      Bugsnag.notify(error);
    })
    .finally(() => {
      loadingCompanies.value = false;
    });
};
const getIdFromVimeoURL = (url) => {
  let videoURL =
    /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/([a-z]*\/)*([0-9]{6,11})\?h=([a-zA-Z0-9]*)[?]?.*/.exec(
      url
    );

  if (videoURL == null) {
    videoURL =
      /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/([a-z]*\/)*([0-9]{6,11})\/?([a-zA-Z0-9]*)[?]?.*/.exec(
        url
      );
  }
  
  try {
    if (videoURL[6]) {
      return videoURL[5] + "?h=" + videoURL[6];
    }

    return videoURL[5];
  } catch {
    return false;
  }
};

const youtubeParser = (url) => {

  const regExp = /^https?:\/\/(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:watch\?.*v=|embed\/))([\w-]{11})(?:\S+)?$/i;
  const regExp2 = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;


  let match = url.match(regExp);

  if(match == null){
    match = url.match(regExp2);

    return match ? match[7] : false;
  }

  return match ? match[1] : false;

};

const fetchVideoId = _debounce(async (url) => {
  isDisabled.value = true
  
  const youtubeId = youtubeParser(url);
  const vimeoId = getIdFromVimeoURL(url);



  errorMessage.value = "";

  if (youtubeId) {
    const isAvailable = await isYoutubeAvailable(youtubeId);

    isDisabled.value = false
    if (isAvailable) {
      featuredVideo.value.youtube_id = youtubeId;
      featuredVideo.value.videoType = 1;
      return;
    } 

    errorMessage.value = "The video you're trying to upload is possibly set as private or has already been deleted.";
    featuredVideo.value.videoUrl = "";
    featuredVideo.value.youtube_id = "";
    
    return;
  } 

  if (vimeoId) {
    errorMessage.value = "";
    const isAvailable = await isVimeoAvailable(vimeoId);

    isDisabled.value = false
    if (isAvailable) {
      featuredVideo.value.youtube_id = vimeoId;
      featuredVideo.value.videoType = 0;
      return;
    }

    errorMessage.value = "The video you're trying to upload is possibly set as private or has already been deleted.";
    featuredVideo.value.videoUrl = "";
    featuredVideo.value.youtube_id = "";

    return
  }

  featuredVideo.value.videoUrl = "";
  featuredVideo.value.youtube_id = "";
  errorMessage.value = "Please input Vimeo or Youtube links only.";

}, 200);

const isSuperAdmin = computed(() => {
  const user = authUser.user;

  if (!user) return false;

  return user.roles.some((role) => role.name === "super-administrator");
});


const user = computed(() => {
  return authUser?.user;
});

onMounted(() => {
  getCompaniesAndDealers();
});
</script>
