import { useLmsSettingsStore } from "@/stores/useLmsSettingsStore";

export default {
    install: (app) => {
        app.directive('has-enrollment', {
            mounted: (el, binding) => {
                if (!binding.value && !useLmsSettingsStore().hasEnrollment) {
                    el.style.display = 'none';
                }
            },
            updated: (el, binding) => {
                if (!binding.value && !useLmsSettingsStore().hasEnrollment) {
                    el.style.display = 'none';
                }
            }
        })

        app.directive('has-require-arp', {
            mounted: (el, binding) => {
                if (!binding.value && !useLmsSettingsStore().hasRequireArp) {
                    el.style.display = 'none';
                }
            },
            updated: (el, binding) => {
                if (!binding.value && !useLmsSettingsStore().hasRequireArp) {
                    el.style.display = 'none';
                }
            }
        })
    }
}