<template>
    <div class="min-w-0">
        <div class="flex items-start gap-x-3">
            <el-tooltip class="box-item" raw-content="true" :content="wrapTooltipContent(reminder.name)" placement="top">
                <p class="text-sm font-semibold leading-6 text-gray-900 truncate">{{ reminder.name }}</p>
            </el-tooltip>
        </div>
        <div class="mt-1 flex items-center gap-x-2 text-sm font-semibold leading-5 text-gray-500">
            <p class="whitespace-nowrap">
                {{ dateFormat(reminder.assignedDateTime) }}
            </p>
        </div>
        <div class="text-justify text-sm leading-5 text-gray-500 gap-x-3 w-full" v-html="formattedText" />
    </div>
</template>
<script setup>
import { modifiedDescription, formatText } from '@/composable/reminder/modified-description';
import { wrapTooltipContent } from '@/composable/wrapTooltipContent';
import { dateFormat } from '@/helpers/date_formatter';
import { ElTooltip } from "element-plus";
import { defineProps, computed } from 'vue'

const props = defineProps({
    reminder: {
        type: Object,
        required: true
    }
})

const reminder = computed(() => props.reminder);
const description = computed(() => {
    return modifiedDescription(reminder.value)
})

const formattedText = computed(() => {
    return formatText(description.value, reminder.value.type)
})
</script>