<template>
  <main class="p-10">
    <div
      class="flex flex-col gap-5 border py-5 px-5 rounded-md shadow"
    >
      <div class="flex flex-row justify-between">
        <h1 class="text-xl text-untitled-gray-400">My Comments</h1>
        <div class="flex flex-row justify-center items-center gap-1 w-[200px]">
          <e-combobox class="flex-1" v-model="sort" :options="options" placeholder="Sort By"></e-combobox>
        </div>
      </div>
      <section class="flex flex-col gap-5" v-if="loading">
        <div
          class="border rounded-md p-5 shadow"
        >
          <el-skeleton :rows="3" />
        </div>

        <div
          class="border rounded-md p-5 shadow"
        >
          <el-skeleton :rows="3" />
        </div>
        <div
          class="border rounded-md p-5 shadow"
        >
          <el-skeleton :rows="3" />
        </div>
      </section>
      <section @scroll="onScroll" v-if="!loading && notes?.length" class="flex flex-col px-5 gap-5 max-h-[500px] overflow-auto">
        <note-card
          v-for="note in notesOnly"
          :key="note.id"
          :note="note"
        />
      </section>
      <section v-if="!loading && !notes?.length">
        <p class="text-untitled-gray-400 font-medium text-center">No notes found.</p>
      </section>
    </div>
  </main>
</template>

<script setup>
import { ref, inject, onMounted,computed,watch } from "vue";
import { ElSkeleton } from "element-plus";
import NoteCard from "@/components/notes/NoteCard.vue";
import Bugsnag from "@bugsnag/js";
import eventBus from "@/plugins/eventBus";
import ECombobox from "@/components/element-components/ECombobox"

const axios = inject("axios");

const loading = ref(true);
const notes = ref([]);
const pageLimit = ref(10);
const currentPage = ref(1);
const totalPage = ref(-1);
const sort = ref()
const options = [
  'A-Z',
  'Z-A'
];

const notesOnly = computed(() => notes.value.filter((note) => (note.parent_id === null)))

const onScroll = ({ target: { scrollTop, clientHeight, scrollHeight } }) => {

  if (scrollTop + clientHeight >= scrollHeight - 5 && currentPage.value < totalPage.value) {
     currentPage.value++
     fetchNotes()
  }
};

const fetchNotes = async () => {
  axios
    .get("/api/v3/user/notes",{
      params:{
        page: currentPage.value,
        limit: pageLimit.value,
        sort: sortedBy?.value?.sort ?? 'notes.created_at',
        sortBy: sortedBy?.value?.sortBy ?? 'desc'
      }
    })
    .then(({ data }) => {
      notes.value = [...notes.value,...data?.data];
      totalPage.value = data?.last_page
      loading.value = false;
    })
    .catch((error) => {
      loading.value = false;
      Bugsnag.notify(error);
    });
};



const sortedBy = computed(() => {
  switch(sort.value){
    case 'A-Z':
      return {
        sort: 'units.name',
        sortBy: 'asc'
      }
    case 'Z-A':
      return {
        sort: 'units.name',
        sortBy: 'desc'
      }
    default: 
      return null
  }
})



watch(sortedBy,() => {
  loading.value = true
  currentPage.value = 1
  notes.value = []
  fetchNotes()
})

onMounted(() => {
  loading.value = true;

  fetchNotes();

  eventBus.$on('DELETE_NOTE',(id) => {
    const index = notes.value.findIndex((note) => (note.id == id))

    notes.value.splice(index,1)
  })

  eventBus.$on('UPDATE_NOTE',(note) => {
    const index = notes.value.findIndex((item) => (item.id == note.id))

    notes.value.splice(index,1,note)
  })
});
</script>
