export const notificationMessage = {
    500: `<p>
            Oops! Something wrong happened. Please click the refresh button to reload or go back to the 
            <a href='/' class="cursor-pointer underline text-blue-500">home page</a>
        </p>`,
    startLearning: (unitId, courseName) => {
        return `<p>
            The ${courseName} course has been added to your library successfully. <br/>
            <a href=/units/${unitId} target="_blank" class="cursor-pointer underline text-blue-500">Start learning</a>
        </p>`;
    },
};
