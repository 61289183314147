<template>
  <div
      v-bind="$attrs"
      class="bg-white w-full border-r-4 border-untitled-gray-300 px-4 pt-5 pb-8 shadow-md animate-pulse"
  >
    <div class="mb-3 md:flex items-center justify-between">
      <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
    </div>
    <div class="md:flex items-center">
      <div class="md:w-full">
        <div class="flex justify-center items-center w-full h-32 bg-gray-300 rounded sm:w-96 dark:bg-gray-700">
          <svg class="w-12 h-12 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
        </div>
        <h4 class="text-untitled-gray-800 font-medium sm:w-3/5 mt-2 text-lg">
          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
        </h4>
      </div>
      <div class="sm:w-32 md:pl-6 md:w-full">
        <div class="container mx-auto">
          <div class="w-full mx-auto mt-5 md:mt-5">
            <div class="h-1 bg-untitled-gray-200 rounded">
              <div
                  class="bg-untitled-gray-400 h-1 rounded relative"
                  :style="'width:100%'"
              ></div>
            </div>
            <div class="flex justify-end items-center pt-1 mt-5">
              <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-44 mb-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkeletonPlaylist"
}
</script>

<style scoped>

</style>