<template>
    <el-space direction="vertical" alignment="flex-start">
        <ul role="list" class="my-1 divide-y divide-untitled-gray-200">
            <li v-for="i in lineCount" :key="i" class="py-2">
                <el-skeleton animated :loading="loading" class="flex items-start my-1">
                    <template #template>
                        <div class="w-full mt-3">
                            <el-skeleton-item variant="h4" class="h4" />
                            <el-skeleton-item variant="text" class="text" />
                        </div>
                    </template>
                </el-skeleton>
            </li>
        </ul>
    </el-space>
</template>
  

  

<script setup>
import { ElSkeleton, ElSkeletonItem, ElSpace } from 'element-plus'
import { computed, defineProps } from 'vue';

const props = defineProps({
    lineCount: {
        type: Number,
        default: 5
    },
    loading: {
        type: Boolean,
        default: false
    }
})

const loading = computed(() => props.loading)
</script>

<style scoped>
.image {
    width: 225px !important;
    height: 73px !important;
}

.h4 {
    width: 70% !important;
}

.text {
    width: 33% !important;
}
</style>