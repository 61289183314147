import { reminderEndpoints } from "@/endpoints/reminder"
import ReminderModel from "@/model/ReminderModel"
import { errorHandler } from "../error_handler"
import { ref } from "vue"
import eventBus from "@/plugins/eventBus"
import { notificationMessage } from "@/helpers/error_message"

export const postReminder = async (payload) => {
    const errorValue = ref(null)
    const { isError, error, data, loading } = await new ReminderModel(reminderEndpoints.resource).post(payload)
    if(isError && error) {
        const { data: errorData }  = errorHandler(error);
        errorValue.value = errorData
    }
    
    if(error && error.response.status === 500) {
        eventBus.$emit(
            'CREATE_NOTIFICATION',
            notificationMessage[500],
            'server_error',
            6500
        )
    }

    return {
        data,
        isError,
        errorValue,
        loading
    }
}