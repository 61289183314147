<template>
  <TransitionRoot appear :show="isOpen" as="template">
   <Dialog as="div" class="relative z-[999] w-full">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-untitled-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 h-3/6">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="max-w-7xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all min-w-[400px]"
            >
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                Assign Playlist
              </DialogTitle>
              <div class="mt-5 flex flex-col gap-5 overflow-y h-full">

                <div class="mt-1 w-full">
                  <label class="block text-sm font-medium text-untitled-gray-700">Users</label>
                    <e-combobox
                      v-model="selectedUsers"
                      name="users"
                      :options="filteredUsers"
                      :loading="loadingUsers"
                      option-name="name"
                      value-key="id"
                      placeholder="Start typing to search for users...."
                      :multiple="true"
                      :filterable="true"
                      :clearable="true"
                      id="Assign-Playlist-Dialog-Users-field"/>
                      <ErrorSpan v-if="errorValue && errorValue.users">{{ errorValue.users }}</ErrorSpan>

                  </div>
                  <div class="mt-1 w-full">
                    <div class="flex justify-between items-center mb-1" >
                      <label class="block text-sm font-medium text-untitled-gray-700">Groups</label>
                      <a class="cursor-pointer px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm true text-center h-[auto] w-[auto] justify-center inline-flex items-center text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
                        @click="handleClickCreateGroup"
                        id="Assign-Playlist-Dialog-Create-button">
                        Create Group
                      </a>
                    </div>
                    <e-combobox
                      v-model="selectedGroups"
                      name="groups"
                      :options="groups"
                      :loading="loadingGroups"
                      option-name="name"
                      value-key="id"
                      :multiple="true"
                      :filterable="true"
                      :clearable="true"
                      placeholder="Start typing to search groups...."
                      id="Assign-Playlist-Dialog-Groups-field" />
                      <ErrorSpan v-if="errorValue && errorValue.groups">{{ errorValue.groups }}</ErrorSpan>
                  </div>
                  <div class="mt-1 w-full" v-if="dealers && dealers.length > 0">
                  <label class="block text-sm font-medium text-untitled-gray-700">Dealers</label>
                    <e-combobox
                      v-model="selectedDealers"
                      name="dealers"
                      :options="dealers"
                      option-name="name"
                      value-key="id"
                      placeholder="Start typing to search dealers...."
                      :multiple="true"
                      :filterable="true"
                      :disabled="dealerLoading"
                      :clearable="true"
                      id="Assign-Playlist-Dialog-Dealers-field"/>

                  </div>
                <div class="mt-1">
                  <label class="block text-sm font-medium text-untitled-gray-700"
                    >Due Date</label
                  >
                  <div class="w-full">
                    <EDatePicker
                      :disabled-below-dates="true"
                      @change="fetchAssigned"
                      v-model="dueDate"
                      :format="'MMM D, YYYY h:mm A'"
                      :value-format="'YYYY-MM-DD h:mm A'"
                      :placeholder="'Pick a Date and Time'"
                      :clearable="true"
                      id="Assign-Playlist-Dialog-Due-Date-field"/>
                    <ErrorSpan v-if="errorValue && errorValue.dueDate">{{ errorValue.dueDate }}</ErrorSpan>
                  </div>
                </div>
                <div class="flex flex-row">
                  <v-button
                    type="button"
                    @click="handleSubmit"
                    class="relative grow justify-center inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
                    :loading="loading"
                    id="Assign-Playlist-Dialog-Save-button"
                    >
                    Save
                  </v-button>

                  <v-button
                    type="button"
                    @click="close()"
                    class="grow relative ml-2 inline-flex  justify-center rounded-md border border-untitled-gray-300 bg-white px-4 py-2 text-base font-medium text-untitled-gray-700 shadow-sm hover:text-untitled-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    :loading="loading"
                    id="Assign-Playlist-Dialog-Cancel-button"
                    >
                    Cancel
                  </v-button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <GroupFormModal :eventId="groupFormModalEventId" />
</template>

<script setup>
import { ref, computed, onMounted, inject, defineEmits,defineProps } from "vue";
import {
  TransitionChild,
  TransitionRoot,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { getUsers, getGroups } from "@/composable/users/getUsers";
import dayjs from "dayjs";
import EventBus from "@/plugins/eventBus";
import Bugsnag from "@bugsnag/js";
import {VButton} from 'revdojo-vue-components'
import ErrorSpan from "../utilities/ErrorSpan.vue";
import ECombobox from "@/components/element-components/ECombobox"
import GroupFormModal from '@/components/groups/GroupFormModal';
import { useFilteredUsers } from '@/composable/groups/useFilteredUsers';
import EDatePicker from "../element-components/EDatePicker.vue";
import { notificationMessage } from "@/helpers/error_message";

const props = defineProps({
  isOpen: {
      type: Boolean,
      default: false
  },
  playlistId: {
      type: Number,
      default: -1
  },
  close: {
      type: Function,
      default: () => {}
  },
  dealers: {
    type: Array,
    required: true,
  },
  dealerLoading: {
    type: Array,
    required: true,
  },
})

const selectedUsers = ref([]);
const selectedGroups = ref([]);
const selectedDealers = ref([]);
const dueDate = ref(null);
const loading = ref(false)
const axios = inject("axios");

const errorValue = ref(null);
const emit = defineEmits(["assign"]);
const { userList, users,loadingUsers } = getUsers();
const { groupList, groups,loadingGroups } = getGroups();

const groupFormModalEventId = 'assignPlaylist';

onMounted(() => {
  userList();
  groupList();
});

function handleSubmit() {
  loading.value = true

  errorValue.value = validateForm();
  if (errorValue.value) {
    loading.value = false;
    return;
  }

  axios.post("/api/v3/learning-paths/assign", assignedForm.value)
  .then(() => {
      EventBus.$emit(
        'CREATE_NOTIFICATION',
        'Assigned successfully',
        'success',
        6500
      )
      loading.value = false
      props.close()
      EventBus.$emit('REFRESH_PLAYLIST_ASSIGNEES')
  })
  .catch((error) => {
    EventBus.$emit(
          'CREATE_NOTIFICATION',
          notificationMessage[500],
          'server_error',
          6500
      )
      Bugsnag.notify(error);
  }).finally(() => {
    loading.value = false
  });
}

const assignedForm = computed(() => {
  let due_date = dayjs(dueDate.value).format("YYYY-MM-DD HH:mm:ss");
  return {
    assigned_users: selectedUsers.value,
    assigned_groups: selectedGroups.value,
    assigned_dealers: selectedDealers.value,
    due_date: due_date,
    playlist_id: props.playlistId,
  };
});

const validateForm = ()=> {
  let errors = {};
  
  if (assignedForm.value.assigned_users.length == 0 && assignedForm.value.assigned_groups.length == 0 && assignedForm.value.assigned_dealers.length == 0) {
    errors.users = "Select at least one User."
    errors.groups = "Select at least one group."
  }

  if (assignedForm.value.due_date == 'Invalid Date')  {
    errors.dueDate = "Due Date is required."
  }

  if (Object.keys(errors).length > 0) {
    return errors;
  }

  return null;
};

const fetchAssigned = () => {
  emit("assign", assignedForm.value);
};

const handleClickCreateGroup = () => {
  EventBus.$emit(`openGroupFormModal${groupFormModalEventId}`);
}

EventBus.$on('addGroup', async (group) => {
  selectedGroups.value.push(group.id)
  groups.value.push(group)
})

const filteredUsers = computed(()=> useFilteredUsers(groups.value, selectedGroups.value, users.value));

</script>
