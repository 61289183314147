<template>
  <div v-bind="$attrs" :id="'course-' + course.id" class="bg-white w-full px-4 pt-5 pb-8 shadow-md mb-2">
    <div class="flex flex-col md:flex-row lg:flex-row">
      <div class="w-full sm:w-52 md:w-52 lg:w-96">
        <el-tooltip :content="wrapTooltipContent(course.name)" effect="dark" :raw-content="true" placement="top-start">
          <h3 class="font-bold text-2xl text-untitled-gray-700 line-clamp-2">
            {{ course.name }}
          </h3>
        </el-tooltip>
      </div>
      <div class="grow" />
      <div>
        <ul class="md:flex items-center justify-between md:pl-6">
          <li class="text-untitled-gray-600 flex items-center text-xs md:mr-2 my-2">
            <ClockIcon class="h-5 w-5 mr-2" />
            <p>{{ course.course_duration }}</p>
          </li>
          <li class="text-untitled-gray-600 flex items-center text-xs md:mr-2 my-2">
            <VideoCameraIcon class="h-5 w-5 mr-2" />
            <router-link :to="'/units/' + course.unit_display.id">
              <span class="font-sans">{{ course.course_details.units_video_count }} Videos</span>
            </router-link>
          </li>
          <li v-if="course.course_details.units_text_count"
            class="text-untitled-gray-600 flex items-center text-xs md:mr-2 my-2">
            <DocumentTextIcon class="h-5 w-5 mr-2" />
            <router-link :to="'/units/' + course.unit_display.id">
              <span class="font-sans">{{ course.course_details.units_text_count }} Text Units</span>
            </router-link>
          </li>
          <li v-if="course.assigned_course" @mouseenter="showToolTip = true" @mouseleave="showToolTip = false"
            class="cursor-pointer relative text-untitled-gray-600 flex items-center text-xs">
            <CalendarIcon class="h-5 w-5 mr-2" />
            <p>{{ formatDate(course.assigned_course.due_date, "MM/DD/YYYY") }}</p>
            <span v-if="showToolTip"
              class="absolute bg-untitled-gray-200 text-untitled-gray-500 rounded p-2 left-0 top-[-30px] lg:top-[20px] md:top-[20px] z-10">
              Expected date and time of completion of the course
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex flex-col md:flex-row lg:flex-row">
      <div class="w-full md:w-96 ">
        <div @click="handleClickToUnits" >
          <div class="relative cursor-pointer w-full md:w-64">
            <img v-lazy="{
              src: course.unit_display.thumbnail,
              error:
                'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg',
              loading:
                'https://via.placeholder.com/800x450?text=Thumbnail%20Loading...',
            }" class="object-cover  object-center aspect-video w-full " />

            <div
              class="absolute flex justify-center items-center w-full bottom-0 h-full object-center inset-0 place-items-center bg-gradient-to-t from-black opacity-50 text-white text-xs text-center leading-4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="object-center place-items-center inset-0 mx-auto w-12 h-12">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
              </svg>
            </div>
          </div>
          <div class="flex items-center">
            <ElTooltip class="box-item" effect="dark" raw-content :content="wrapTooltipContent(course.unit_display.name)"
              placement="top-start">
              <h4 class="text-untitled-gray-800 font-medium mt-2 text-lg truncate">
                <!-- {{ course.unit_display.name }} -->
              </h4>
            </ElTooltip>
          </div>
        </div>
      </div>
      <div class="grow hidden sm:block" />
      <div class="w-full md:w-[400px]">
        <!-- progress bar -->
        <div class="w-full">
          <div class="h-1 bg-untitled-gray-200 rounded">
            <div class="bg-untitled-gray-400 h-1 rounded relative" :style="'width: ' + course.completed_percentage + '%'">
            </div>
          </div>
          <div class="flex lg:justify-end md:justify-start items-center pt-5">
            <p class="text-xs text-untitled-gray-500 font-bold">
              {{ course.completed_percentage }}% Complete
            </p>
          </div>
        </div>
        <div class="flex lg:justify-end md:justify-start items-center pt-1 mt-5">
          <template v-if="!course.is_enrolled && hasIsEnrollProperty">
            <ViewCourse v-model:showEnrollCourse="showEnrollCourse" :course="course" :course-id="course.id" @onEnrolledCourse="handleClickEnrollNow"/>
          </template>
          <template v-else>
            <button @click="toggleAssignCourse"
              class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-600 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 mr-2">
              Assign Course
            </button>
            <router-link :to="'/units/' + course.unit_display.id"
              class=" px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-600 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500">
              {{
                course.completed_percentage >= 100
                ? "Retake Course"
                : course.started || course.completed_percentage > 0
                  ? "Continue Learning"
                  : "Start Learning"
              }}
            </router-link>
          </template>
        </div>
        <!-- end of progress bar -->
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  VideoCameraIcon,
  ClockIcon,
  DocumentTextIcon,
  CalendarIcon,
} from "@heroicons/vue/outline";

import EventBus from '@/plugins/eventBus';
import { ref, defineProps, defineAsyncComponent, computed, inject } from 'vue'
import dayjs from "dayjs";
import { ElTooltip } from 'element-plus';
import { wrapTooltipContent } from '@/composable/wrapTooltipContent';
import { useRouter } from "vue-router";
// import ViewCourse from "@/components/utilities/ViewCourse.vue";

const ViewCourse = defineAsyncComponent(() => import("@/components/utilities/ViewCourse.vue"));

const props = defineProps({
  course: {
    type: Object,
    required: true,
  },
})

const router = useRouter();
const courseSectionProvide = inject("fetchCourses")
const showToolTip = ref(false);
const course = computed(() => props.course);
const hasIsEnrollProperty = computed(() => {
  if(course.value && course.value.id) {
    const object = structuredClone(course.value);
    return Object.prototype.hasOwnProperty.call(object, 'is_enrolled');
  }

  return false;
})
const showEnrollCourse = ref(false);

const toggleAssignCourse = () => {
  EventBus.$emit(`toggleAssignCourse`, props?.course);
};


const formatDate = (value, format) => {
  return dayjs(value).format(format);
};

const handleClickToUnits = () => {
  if(course.value && hasIsEnrollProperty.value &&!course.value.is_enrolled) {
    showEnrollCourse.value = true;
    return;
  }

  router.push(`/units/${props.course.unit_display.id}`)
}

const handleClickEnrollNow = () => {
  courseSectionProvide.fetchCourses()
}
</script>
