import { errorHandler } from "@/composable/error_handler";

export const extractTitleFromLink = (link) => {
    let domainName = 'Link'
    try {
        // Parse the link to extract the domain
        const parsedLink = new URL(link);
        domainName = parsedLink.hostname;
      } catch (error) {
        errorHandler(error)
      }

    return domainName;
}