<template>
  <SkeletonHomepageMyVideos v-if="loading" />
  <div v-else-if="!loading && videos.length > 0">
    <div class="flow-root mt-1">
      <ul role="list" class="my-1 divide-y divide-untitled-gray-200">
        <li v-for="video in videos" :key="video.id" class="py-1">
          <div class="flex items-center space-x-4">
            <div class="flex-shrink-0">
              <img v-if="video?.thumbnail || video?.unit?.thumbnail" class="h-20 w-32"
                :src="video.thumbnail ? video.thumbnail : video.unit.thumbnail" alt="" />
              <img v-else src="https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg"
                class="h-20 w-32">
            </div>
            <div class="flex-1 min-w-0">
              <p class="text-sm font-medium text-untitled-gray-900 truncate">
                {{ video.name }}
              </p>
              <p class="text-xs text-untitled-gray-500 truncate">
                {{ video.module != undefined ? video.module.name : '' }}
                {{ video.unit != undefined && video.unit.module != undefined ? video.unit.module.name : '' }}
              </p>
              <p class="text-xs text-untitled-gray-500 truncate">
                {{ videoTab(video) }} - {{ formatDate(video.due_date ?? video.created_at) }}
              </p>
              <p class="text-xs text-black-500 truncate">

              </p>
              <p class="text-xs font-semibold text-untitled-gray-500 truncate">
                {{ video.status }}
              </p>
            </div>
            <div class="flex">
              <TrashIcon v-if="isCreatedBy(video)" @click="handleClickDelete(video)"
                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 cursor-pointer" aria-hidden="true" />
              <router-link :to="redirectUrl(video)"
                class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-untitled-gray-300 text-sm leading-5 font-medium rounded-full text-untitled-gray-700 bg-white hover:bg-untitled-gray-50">
                View
              </router-link>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="mt-6">
      <router-link to="/my-videos"
        class="w-full flex justify-center items-center px-4 py-2 border border-untitled-gray-300 shadow-sm text-sm font-medium rounded-md text-untitled-gray-700 bg-white hover:bg-untitled-gray-50">
        View all
      </router-link>
    </div>
  </div>
  <div v-else class="text-center">
    <div class="block">No videos found.</div>
  </div>

  <ConfirmDialog :dialogTitle="'Remove Confirmation'" :confirmButtonLabel="'OK'" :okButton="handleDeleteUnit"
    :cancelButton="handleClickCancel" :loading="confirmLoading" :open="confirmShow" id="Homepage-MyVideos-Dialogbox">{{
      confirmMessage }}
  </ConfirmDialog>

  <notification-dialog v-if="showNotification" :content="'Unit removed successfully'" :close="closeNotification" />
</template>

<script setup>
import { ref, inject, computed, onMounted } from 'vue';
import dayjs from 'dayjs';
import { TrashIcon } from '@heroicons/vue/outline';
import { useAuthStore } from '@/stores';
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";
import NotificationDialog from '@/components/partials/NotificationDialog.vue';
import Bugsnag from "@bugsnag/js";
import EventBus from "@/plugins/eventBus";
import SkeletonHomepageMyVideos from '@/components/loaders/SkeletonHomepageMyVideos.vue';

const authStore = useAuthStore();
const confirmShow = ref(false);
const confirmLoading = ref(false);
const loading = ref(false);
const confirmMessage = ref('Are you sure you want to remove this unit?');
const showNotification = ref(false);
const selectedUnit = ref(null);


var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const videos = ref([]);

const axios = inject('axios');

const isCreatedBy = (video) => {
  return (video.created_by ?? video.unit.created_by) == authUser.value.id
}

const getUnits = async () => {
  loading.value = true;

  await axios.get('api/v3/units', {
    params: {
      page: 1,
      perPage: 12,
      videoType: 'all',
      sortBy: 'created_at',
      sortOrder: 'desc'
    }
  })
    .then(response => {
      videos.value = response.data.data;
    }).catch(error => {
      Bugsnag.notify(error);
    })


  loading.value = false;
}


const redirectUrl = function (video) {
  return video?.unit ?
    { name: 'units', params: { id: video.unit.id } } :
    { name: 'units', params: { id: video.id } };
}

const formatDate = function (date) {
  return dayjs(date).format('MM/DD/YYYY');
}


const videoTab = function (video) {
  let tab = 'Uploaded';
  
  if (video.assigned_by?.id !== null && video.user_id == authUser.value.id) {
    tab = 'Assigned to you';
  }

  if (video.user_id !== null && video.assigned_by?.id == authUser.value.id) {
    tab = 'Assigned by you';
  }

  return tab;
}

const authUser = computed(() => authStore.$state.user);

const handleClickDelete = (unit) => {
  confirmShow.value = true;
  selectedUnit.value = unit;
}

const handleClickCancel = () => {
  confirmShow.value = false;
  selectedUnit.value = null;
}

const handleDeleteUnit = async () => {
  if (!selectedUnit.value) {
    return;
  }

  confirmLoading.value = true;

  await axios.delete(`/api/units/${selectedUnit.value.id}`)
  .then((response) => {
    if (response.status === 200) {
      getUnits();

      EventBus.$emit("REFRESH_LEARNING_PATH");

      notification();
      confirmLoading.value = false;
      confirmShow.value = false;
    }
  }).catch((error) => {
    Bugsnag.notify(error);
  })

}


const notification = () => {
  EventBus.$emit(
    'CREATE_NOTIFICATION',
    'Unit removed successfully',
    'success',
    6500
  )
}

const closeNotification = ()=>{
  showNotification.value = false;
}


onMounted(async () => {
  await getUnits();
})

</script>
