import { defineStore } from 'pinia';
import { useRouter } from "vue-router";
import axios from "axios";
export const useAuthStore = defineStore({
    persist: true,
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: null,
        returnUrl: null,
        token: null,
    }),
    actions: {
        async login(clientid) {
            // auth Route
            axios
                .post('/api/sso-post-login', {
                    clientid,
                })
                .then(({ data }) => {
                    // update pinia state
                    this.user = data.user;
                    axios.defaults.headers.common.Authorization = `Bearer ${data.token}`
                    // store user details and jwt in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(data.user));
                    localStorage.setItem('bearer_token', JSON.stringify(data.token))

                    // redirect to previous url or default to home page
                    useRouter().push('/');
                })
                .catch(() => {
                    //
                });
        },
        logout() {
            this.user = null;
            localStorage.removeItem('user');
            localStorage.removeItem('bearer_token');
            useRouter().push('/login');
        }
    },
    getters: {
        fullName() {
            const name = this.user.name.split(" ")
            const capitalizeFirstLetter = name[0].charAt(0).toUpperCase() + name[0].slice(1);
            const endsWithS = capitalizeFirstLetter.endsWith('s')
            return endsWithS ? capitalizeFirstLetter : `${capitalizeFirstLetter}'s`
        },
        isSuperAdmin() {
            if (!this.user) {
                return false;
            }

            if (!this.user.id) {
                return false
            }

            return this.user.roles.some(
                (role) => role.name === "super-administrator"
            );
        },
        isSingleUser() {
            if (!this.isSuperAdmin && this.user && this.user.dealer && this.user.dealer.id) {
                return Boolean(this.user.dealer.is_single_user)
            }

            return false;
        },
        isNotSingleRooftop() {
            if (!this.user) {
                return false;
            }

            if (!this.isSuperAdmin && this.user && this.user.dealer && this.user.dealer.is_automotive) {
                return false;
              }
            
              return true
        },
        isDealerManager() {
            if (!this.user) {
                return false;
            }

            if(!this.user.id) {
                return false
            }

            return this.user.roles.some(
                (role) => role.name === "specific-dealer-manager"
            );
        },
        isAccountManager() {
            if (!this.user) {
                return false;
            }

            if (!this.user.id) {
                return false
            }

            return this.user.roles.some(
                (role) => role.name === "account-manager"
            );
        }
    }
});