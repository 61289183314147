<script setup>
import { useAuthStore } from "@/stores";
import { ElPopover } from "element-plus";
import { computed, ref } from "vue";
import WeeklyReportSettings from "../WeeklyReportSettings.vue";

const authStore = useAuthStore();

const isSuperAdmin = computed(() => authStore.isSuperAdmin);
const isAccountManager = computed(() => authStore.isAccountManager)
const isWeeklyReportSettingOpen = ref(false)
const routes = [
    {
        name: "Manage Users",
        to: "/central/manage-users",
        urlType: "central",
    },
    {
        name: "Manage Curriculums",
        to: "/manage/curriculums",
        urlType: "lms",
    },
    {
        name: "Manage Courses",
        to: "/manage/courses",
        urlType: "lms",
    },

    {
        name: "Manage Weekly Report",
        urlType: "button",
    },
]

const filteredRoutes = computed(() => {
    return routes.filter((route) => {
        if(!isSuperAdmin.value && !isAccountManager.value && route.name.toLowerCase() === "manage courses") {
            return false;
        }

        if(!isSuperAdmin.value && route.name.toLowerCase() === "manage curriculums") {
            return false;
        }

        return true;
    })
})

const handleClickShow = (route) => {
    switch (route.name.toLowerCase()) {
        case 'manage weekly report':
            isWeeklyReportSettingOpen.value = true;
            break;
    
        default:
            break;
    }
}
</script>
<template>
    <div v-bind="$attrs">
        <el-popover :width="220" popper-style="padding: 5px !important">
            <template #reference>
                <a
                    class="text-untitled-gray-500 border-transparent hover:border-untitled-gray-300 border-b-2 hover:text-untitled-gray-700 px-1 pt-1 text-sm w-full h-full flex justify-center items-center font-medium">
                    Admin Portal
                </a>

            </template>
            <template #default>
                <div class="w-full flex flex-col justify-start items-start">
                    <div class="w-full" v-for="(route, index) in filteredRoutes" :key="index">
                        <router-link
                            class="w-full px-2 py-2 hover:bg-gray-200 text-sm text-untitled-gray-700 flex justify-start items-start"
                            :to="route.to" v-if="route.urlType === 'lms'">
                            {{ route.name }}
                        </router-link>
                        <a :href="route.to" v-else-if="route.urlType === 'central'" target="_blank"
                            class="w-full px-2 py-2 hover:bg-gray-200 text-sm text-untitled-gray-700 flex justify-start items-start">{{
                                route.name }}</a>
                        <button v-else
                            class="w-full px-2 py-2 hover:bg-gray-200 text-sm text-untitled-gray-700 flex justify-start items-start"
                            @click="handleClickShow(route)">{{ route.name }}</button>
                    </div>

                </div>
            </template>
        </el-popover>
        <WeeklyReportSettings v-model="isWeeklyReportSettingOpen" />
    </div>
</template>
<style lang="scss" scoped></style>