<template>
  <div class="bg-white px-4 py-2 border-b border-untitled-gray-200 sm:px-6">
    <div class="-ml-4 -mt-2 flex flex-row-reverse justify-between flex-wrap sm:flex-nowrap">
      <div class="ml-4 mt-2 flex-shrink-0">
        <Menu as="div" class="relative inline-block text-left z-10">
          <div>
            <MenuButton
              class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              Options
              <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
            <MenuItems
              class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
              <div class="py-1">
                <MenuItem v-slot="{ active }" @click="openModal">
                <a href="#" :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  ]">
                  <PlusIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Create Reminder
                </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                <router-link to="/reminders" :class="[
                    active ? 'bg-gray-100 text-gray-900' : ' text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  ]">
                  <EyeIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  View All Reminders
                </router-link>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
  </div>
  <div>
    <template v-if="reminders && !reminders.length && !loading">
      <div class="flex justify-center items-center content-center h-56">
        <h3>No reminders</h3>
      </div>
    </template>
    <template v-if="reminders && reminders.length && !loading">
      <ul role="list" class="divide-y divide-gray-100 px-2">
        <li v-for="reminder in reminders" :key="reminder.id" class="flex items-center justify-between gap-x-6 py-5">
          <ReminderData :reminder="reminder"/>
          <div class="flex flex-none items-center gap-x-4">
            <router-link :to="{ name: 'reminders', query: { id: reminder.id }}"
              class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
              View
            </router-link>
          </div>
        </li>
      </ul>
      <div class="flex justify-center mb-2">
        <router-link to="/reminders"
              class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
            View more reminders
        </router-link>
      </div>
    </template>
    <div v-if="loading">
      <GlobalListSkeleton :display="3" :show-avatar="false" />
    </div>
  </div>
  <div>
    <create-reminder 
      v-if="isOpen" 
      @changeCompany="handleOnChangeCompany"
    @changeDealer="handleOnChangeDealer"
      :isOpen="isOpen" :handleCloseModal="closeModal" :users="users" eventBusName="NEW_REMINDER_HOMEPAGE"/>
</div>
</template>

<script setup>
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";

import { ChevronDownIcon, PlusIcon, EyeIcon } from "@heroicons/vue/solid";
import CreateReminder from "@/components/reminders/CreateReminderForm/CreateReminder";
import { defineProps, computed, ref, inject } from "vue";
import GlobalListSkeleton from "@/components/layout/GlobalListSkeleton.vue";
import ReminderData from "./ReminderData.vue";

const props = defineProps({
  reminders: {
    type: Array,
    default: () => []
  },
  users: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const { handleSelectedCompanyDealer } = inject('homePageReminder')

const selectedCompanyIds = ref([])
const selectedDealerIds = ref([])
const reminders = computed(() => props.reminders);
const users = computed(() => props.users);
const loading = computed(() => props.loading)
const isOpen = ref(false)

const closeModal = () => {
  isOpen.value = false
}

const openModal = () => {
  isOpen.value = true
}

const handleOnChangeCompany = (companyIds) => {
  selectedCompanyIds.value = companyIds
  handleSelectedCompanyDealer(selectedCompanyIds.value, selectedDealerIds.value)
}

const handleOnChangeDealer = (dealerIds) => {
  selectedDealerIds.value = dealerIds
  handleSelectedCompanyDealer(selectedCompanyIds.value, selectedDealerIds.value)
}
</script>


<style>
textarea {
  resize: none;
}
</style>