<template>
    <p class="text-red-500 mt-[5px] text-xs break-word" v-bind="$attrs">
        <slot></slot>
    </p>
</template>

<script setup>

</script>

<style>
.break-word {
    word-break: break-word;
}
</style>